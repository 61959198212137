import { getListings } from "@/services/api";
import { useQuery } from "react-query";
import PropertyCard from "@/atoms/PropertyCard";
import React, { Fragment, useEffect, useState } from "react";
import DashboardButton from "@/atoms/DashboardButton";
import routes from "@/constants/routes";
import { useRouter } from "next/router";
import { useWindowSize } from "@/shared/hooks/useWindowSize";
import Screen from "@/constants/screens";

const FlatsAndHousesToRent = () => {
  const offset = 0;
  const [limit, setLimit] = useState<number>(3);
  const router = useRouter();
  const { width } = useWindowSize();
  const { data: listingsData } = useQuery(
    ["listings", limit],
    () => getListings({ limit, offset, search: "" }),
    {
      enabled: true,
    },
  );
  useEffect(() => {
    if (width >= Screen.Tablet_768 && width <= Screen.DESKTOP_1280) {
      // Tablet
      setLimit(2);
    } else {
      // Mobile and desktop
      setLimit(3);
    }
  }, [width]);

  return (
    <>
      <h2 className="text-4xl font-bold lg:text-5xl text-landing-blue text-center mt-16">
        Flats and houses to rent with Keyzy
      </h2>
      <h3 className="text-2xl font-serif lg:text-3xl text-landing-blue text-center my-6">
        Rent-to-own properties across London coming soon
      </h3>
      <section className="flex flex-wrap items-center justify-left w-auto mt-9 max-w-[90rem] mb-5 md:mb-12 mx-auto lg:px-[1.5rem]">
        {listingsData?.result.map((property, idx) => (
          <Fragment key={idx}>
            <div className={`flex flex-col w-full md:w-1/2 lg:w-1/3 mb-8 px-5 md:px-4`}>
              <PropertyCard property={property} />
            </div>
          </Fragment>
        ))}
      </section>

      <div className="flex justify-center mb-5 w-full md:w-auto items-center px-5 md:px-0">
        <DashboardButton
          className="px-4 py-2 w-full md:w-auto rounded-sm md:rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
          variant="orangeFull"
          onClick={(e) => {
            e.preventDefault;
            router.push({
              pathname: routes.searchHomes,
            });
          }}
        >
          Search all {(listingsData?.total || 0) > 1 && `${listingsData?.total}`} homes
        </DashboardButton>
      </div>
    </>
  );
};

export default FlatsAndHousesToRent;
