import React from "react";
import RightArrow from "@/public/icons/hiw-right-arrow.svg";
import Link from "next/link";
import routes from "@/constants/routes";
import Image from "next/image";
import { useWindowSize } from "@/shared/hooks/useWindowSize";
import Screen from "@/constants/screens";
import { Props } from "./types";
import { formatPrice } from "@/utils/formatPrice";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

const PropertyCard = ({ property }: Props) => {
  const { width } = useWindowSize();

  function formatISODateToReadableDate(inputDateString: string) {
    const date = new Date(inputDateString);

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const year = date.getFullYear();
    const monthIndex = date.getMonth();
    const day = date.getDate();

    const formattedDateString = `${monthNames[monthIndex]} ${day}, ${year}`;

    return formattedDateString;
  }
  return (
    <a href={`/properties/${property.district_code}/${property.id}`}>
      <div className="group" role="tab" id={property.id}>
        <div className="flex w-full group-hover:opacity-80 bg-landing-gray relative rounded-tl-[15px] rounded-tr-[15px] cursor-pointer">
          <Image
            src={property?.pictures?.data[0].attributes?.url}
            alt={property?.pictures?.data[0].attributes?.name}
            className="rounded-tl-[15px] rounded-tr-[15px]"
            priority
            style={{
              width: "100%",
              height: "auto",
            }}
            width={500}
            height={300}
          />
          {property.coming_soon && (
            <div className="absolute w-1/2 h-2/3 -top-[1.7%] -left-[5.8%]">
              <Image
                src={"/icons/coming-soon-ribbon.png"}
                alt={"Ribbon"}
                layout="fill"
                objectFit="cover"
                className=""
                priority
              />
            </div>
          )}
        </div>
        <div className="bg-landing-gray  rounded-bl-[15px] rounded-br-[15px] px-3.5 py-4">
          <div className="font-serif text-[32px] leading-10 h-10 min-h-10 mb-5 mt-3.5 line-clamp-2 flex justify-center">
            {property.short_title}
          </div>
          <div className="text-[32px] leading-7 h-10 min-h-10 mb-5 mt-3.5 flex flex-row justify-between">
            <div className="w-1/3 flex flex-row justify-center items-end">
              <div className="w-[50.29px] relative mr-2.5 h-[33px]">
                <Image
                  src={"/icons/bedroom.svg"}
                  alt={"bedroom"}
                  layout="fill"
                  objectFit="contain"
                  className=""
                  priority
                />
              </div>
              <div>{property.bedrooms}</div>
            </div>
            <div className="w-1/3 flex flex-row justify-center items-end">
              <div className="w-[50.29px] relative mr-2.5 h-[32px]">
                <Image
                  src={"/icons/bathroom.svg"}
                  alt={"bathroom"}
                  layout="fill"
                  objectFit="contain"
                  className=""
                  priority
                />
              </div>
              <div className="flex">{property.bathrooms}</div>
            </div>
            <div className="w-1/3 flex flex-row justify-center items-end">
              <div className="w-[50.29px] relative mr-2.5 h-[27px]">
                <Image
                  src={"/icons/living-room.svg"}
                  alt={"living-room"}
                  layout="fill"
                  objectFit="contain"
                  className="flex"
                  priority
                />
              </div>
              <div className="flex text-end">{property.living_rooms}</div>
            </div>
          </div>

          <div className="mb-5 text-lg leading-[23px] font-semibold h-[2.875rem] min-h-[2.875rem] line-clamp-2">
            <p>
              {formatPrice(Number(property.pcm || 0))} per month fixed for {property.lease_length}{" "}
              years. <br />
              Option to buy the home for {formatPrice(Number(property.future_buy_back_price || 0))}.
            </p>
          </div>
          <div className="h-[5.75rem] min-h-[5.75rem] text-base leading-[23px] mb-5 line-clamp-4">
            <a
              className="hover:underline"
              href={`/properties/${property.district_code}/${property.id}`}
            >
              <ReactMarkdown
                className={"markdownContentListings"}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
              >
                {property.short_description}
              </ReactMarkdown>
            </a>
          </div>
          <div
            className={`flex  flex-row items-center justify-center md:justify-between align-middle w-full`}
          >
            <div className={`w-full flex flex-row justify-between align-middle items-end`}>
              <div className="flex flex-row justify-between">
                <a
                  className="flex flex-row align-middle items-center text-landing-orange font-bold"
                  href={`/properties/${property.district_code}/${property.id}`}
                >
                  {width < Screen.Mobile_375 ? "See details" : "See full details"}{" "}
                  <RightArrow className="ml-3" />
                </a>
              </div>
              <div className={`w-auto flex flex-col text-right items-end `}>
                <div className="font-semibold text-landing-blue/60 w-max md:w-auto">Added on</div>
                <div className="font-semibold w-max">
                  {formatISODateToReadableDate(property.added_on)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default PropertyCard;
